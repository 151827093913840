@import url("https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap");

body {
  background-color: #282c34;
}

.App {
  text-align: center;
}

.App-header {
  justify-content: space-between;
  color: white;
  padding: 20px;
}

.App-title {
  font-family: "Rock Salt", cursive;
  font-size: 30px;
}

.App-login {
  background: #f58553;
  border: none;
  border-radius: 3px;
  color: #fff;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
}

.App-login:hover {
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
