@import url(https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background-color: #282c34;
}

.App {
  text-align: center;
}

.App-header {
  justify-content: space-between;
  color: white;
  padding: 20px;
}

.App-title {
  font-family: "Rock Salt", cursive;
  font-size: 30px;
}

.App-login {
  background: #f58553;
  border: none;
  border-radius: 3px;
  color: #fff;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
}

.App-login:hover {
  cursor: pointer;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mo-fire {
  position: fixed;
  bottom: -50px;
  z-index: 4;
  width: 100%;
}

/* #svg-view {
  width: 1000px;
  height: 125px;
  left: 0px;
} */

.mo-fire svg {
  height: auto;
  position: relative;
}
.flame {
  -webkit-animation-name: flameDisappear;
          animation-name: flameDisappear;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  opacity: 0.5;
  transform-origin: 45% 45% 0;
}
.flame.one {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
}
.flame.two {
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.flame-main {
  -webkit-animation-name: flameMovement;
          animation-name: flameMovement;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.flame-main.one {
  -webkit-animation-duration: 2.2s;
          animation-duration: 2.2s;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.flame-main.two {
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.flame-main.three {
  -webkit-animation-duration: 2.1s;
          animation-duration: 2.1s;
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
}
.flame-main.four {
  -webkit-animation-duration: 3.2s;
          animation-duration: 3.2s;
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
}
.flame-main.five {
  -webkit-animation-duration: 2.5s;
          animation-duration: 2.5s;
  -webkit-animation-delay: 5s;
          animation-delay: 5s;
}
@-webkit-keyframes flameMovement {
  50% {
    transform: scale(0.98, 1) translate(0, 2px) rotate(-1deg);
  }
}
@keyframes flameMovement {
  50% {
    transform: scale(0.98, 1) translate(0, 2px) rotate(-1deg);
  }
}
@-webkit-keyframes flameDisappear {
  0% {
    transform: translate(0) rotate(180deg);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(-10px, -40px) rotate(180deg);
    opacity: 0;
  }
}
@keyframes flameDisappear {
  0% {
    transform: translate(0) rotate(180deg);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(-10px, -40px) rotate(180deg);
    opacity: 0;
  }
}

